import React, { useRef, useEffect, useContext, useState, useMemo } from "react";
import { Chart, BarController, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";
import { DatasetsContext } from "../Datasets";
import { tailwindConfig } from "@shared/utils/helpers";
import { ParametricQuantitative } from "@shared/types/databaseTypes";
import { CHART_DEBOUNCE_DELAY } from "../constants";

Chart.register(BarController, BarElement, CategoryScale, LinearScale, Tooltip, Legend);

interface ChartingCanvasElement extends HTMLCanvasElement {
  chart?: Chart;
}

interface DatasetAnalyticsPerformanceHistogramProps {
  focusedIndex: number | null;
  minValue: number | null;
  maxValue: number | null;
  numBins: number | null;
  recomputeTrigger: number;
}

// Define the type for a bin
interface Bin {
  min: number;
  max: number;
  counts: number;
}

const DatasetAnalyticsPerformanceHistogram: React.FC<DatasetAnalyticsPerformanceHistogramProps> = ({ focusedIndex, minValue, maxValue, numBins, recomputeTrigger }) => {
  const canvas = useRef<ChartingCanvasElement>(null);
  const context = useContext(DatasetsContext);
  const [chart, setChart] = useState<Chart | null>(null);

  // Function to dynamically calculate bins based on data
  const createBins = (data: ParametricQuantitative[]): Bin[] => {
    // Exit if null or invalid
    if (minValue === null || maxValue === null || numBins === null || maxValue <= minValue) {
      return []
    }

    // Calculate bin width
    const binWidth = (maxValue - minValue) / numBins;

    // Create bins
    let bins: Bin[] = [];
    let currentBinMin = minValue;
    while (currentBinMin < maxValue) {
      bins.push({
        min: currentBinMin,
        max: currentBinMin + binWidth,
        counts: 0,
      });
      currentBinMin += binWidth;
    }

    // Assign data to bins
    data.forEach((item) => {
      const value = item.value;
      const bin = bins.find((bin) => value >= bin.min && value < bin.max);
      if (bin) {
        bin.counts += 1;
      }
    });

    return bins;
  };

  // Define a highlight color
  const highlightColor = (tailwindConfig().theme?.colors?.blue as any)?.[400]; // Pink color for focus and hover

  // Compute chart data using useMemo
  const chartData = useMemo(() => {
    // Logic to process context.data and create bins as before
    const bins = createBins(context.data as ParametricQuantitative[]);
    return {
      labels: bins.map((bin) => `${bin.min.toFixed(2)}-${bin.max.toFixed(2)}`),
      datasets: [
        {
          label: "Value Distribution",
          data: bins.map((bin) => bin.counts),
          backgroundColor: (tailwindConfig().theme?.colors?.blue as any)?.[200],
        },
      ],
      bins, // Keep the bins for later reference
    };
  }, [context.data, recomputeTrigger]);

  useEffect(() => {
    if (!chart) return;

    let backgroundColors: string[];

    if (focusedIndex !== null && focusedIndex >= 0 && focusedIndex < context.data.length) {
      const focusedData = context.data[focusedIndex] as ParametricQuantitative;
      const focusedValue = focusedData.value;
      const focusedBinIndex = chartData.bins.findIndex((bin) => focusedValue >= bin.min && focusedValue < bin.max);

      if (focusedBinIndex !== -1) {
        backgroundColors = chartData.bins.map((_, index) =>
          index === focusedBinIndex ? highlightColor : (tailwindConfig().theme?.colors?.blue as any)?.[200],
        );

        const pixelX = chart.scales.x.getPixelForValue(focusedBinIndex);
        const pixelY = chart.scales.y.getPixelForValue(chart.data.datasets[0].data[focusedBinIndex] as number);

        chart.tooltip?.setActiveElements([{ datasetIndex: 0, index: focusedBinIndex }], { x: pixelX, y: pixelY });
      } else {
        backgroundColors = chartData.bins.map(() => (tailwindConfig().theme?.colors?.blue as any)?.[200]);
        chart.tooltip?.setActiveElements([], { x: 0, y: 0 });
      }
    } else {
      backgroundColors = chartData.bins.map(() => (tailwindConfig().theme?.colors?.blue as any)?.[200]);
      chart.tooltip?.setActiveElements([], { x: 0, y: 0 });
    }

    chart.data.datasets[0].backgroundColor = backgroundColors;
    chart.update();
  }, [focusedIndex]);

  useEffect(() => {
    let chart: Chart | null = null;
    try {
      let debounceTimer: NodeJS.Timeout;
      debounceTimer = setTimeout(() => {
        if (canvas.current === null) return;
        chart = new Chart(canvas.current, {
          type: "bar",
          data: {
            labels: chartData.labels,
            datasets: [
              {
                label: "Value Distribution",
                data: chartData.datasets[0].data,
                backgroundColor: (tailwindConfig().theme?.colors?.blue as any)?.[200], // Your specified color
              },
            ],
          },
          options: {
            scales: {
              y: {
                beginAtZero: true,
              },
            },
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (context) {
                    return `${context.parsed.y}`; // Include SNs in the tooltip
                  },
                },
              },
              legend: {
                display: true,
                position: "bottom",
              },
            },
            maintainAspectRatio: false,
          },
        });
        setChart(chart);
      }, CHART_DEBOUNCE_DELAY);
      return () => {
        clearTimeout(debounceTimer);
        if (chart) {
          chart.destroy();
        }
      };
    } catch (error) {
      console.error("Chart initialization failed:", error);
    }
  }, [chartData]); // Dependencies: canvas ref, chartData from useMemo, and the chart instance

  return (
    <div className="h-full w-full p-2">
      <canvas ref={canvas}></canvas>
    </div>
  );
};

export default DatasetAnalyticsPerformanceHistogram;
