import * as React from "react";

import { cn } from "@shared/utils/tailwind";

const sizeClasses = {
  xs: "h-6 px-2 text-xs",
  sm: "h-7 px-2 text-sm",
  md: "h-9 px-3 py-2 text-sm",
  lg: "h-11 px-4",
};

const validationStateClasses = {
  valid: "!border-green-500 hover:!border-green-500 focus:!border-green-500",
  invalid: "!border-rose-500 hover:!border-rose-500 focus:!border-rose-500",
  warning: "!border-yellow-500 hover:!border-yellow-500 focus:!border-yellow-500",
};

interface InputProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "size" | "onChange"> {
  onValueChange?: any;
  size?: keyof typeof sizeClasses;
  validationState?: "valid" | "invalid" | "warning" | undefined;
}

interface TextInputProps extends Omit<InputProps, "onValueChange"> {
  onValueChange?: (value: string) => void;
}

interface NumberInputProps extends Omit<InputProps, "onValueChange"> {
  onValueChange?: (value: number) => void;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, validationState, onValueChange, size = "md", type = "text", ...props }, ref) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onValueChange && onValueChange(e.target.value);
    };

    const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (type === "number") {
        onValueChange && onValueChange(e.target.valueAsNumber);
        return;
      }
    }

    return (
      <input
        type={type}
        className={cn(
          sizeClasses[size],
          validationState ? validationStateClasses[validationState] : "",
          "!border-serial-palette-200 placeholder:text-serial-palette-500 focus-visible:ring-serial-palette-600 flex w-full rounded border bg-white py-2 ring-offset-white placeholder:font-light focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className,
        )}
        onChange={handleChange}
        onBlur={handleBlur}
        ref={ref}
        {...props}
      />
    );
  },
);
Input.displayName = "Input";

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>((props, ref) => <Input {...props} ref={ref} type="text" />);
export const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>((props, ref) => (
  <Input {...props} ref={ref} type="number" />
));
