import React, { useContext, useEffect, useState } from "react";
import { DatasetsContext } from "../Datasets";
import Modal from "@shared/components/primitives/Modal";
import Button from "@shared/components/primitives/Button";
import { NumberInput } from "@shared/components/primitives/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
import { ParametricQuantitative } from "@shared/types/databaseTypes";
import { ToastContext } from "@shared/context/ToastProvider";
import { ToastType } from "@shared/components/Toast";

interface DatasetChartEditModalProps {
  setMinValue: React.Dispatch<React.SetStateAction<number | null>>;
  setMaxValue: React.Dispatch<React.SetStateAction<number | null>>;
  setNumBins: React.Dispatch<React.SetStateAction<number | null>>;
  setChangeFlag: React.Dispatch<React.SetStateAction<number>>;
}

export const calculateLimits = (data: ParametricQuantitative[]) => {
  const values = (data).map((item) => item.value);
  if (values.length === 0)
    return null;

  return {
    minValue: Math.min(...values),
    maxValue: Math.max(...values),
    numBins: Math.ceil(Math.sqrt(values.length))
  }
}

const DatasetChartEditModal: React.FC<DatasetChartEditModalProps> = ({ setMinValue, setMaxValue, setNumBins, setChangeFlag }) => {
  const context = useContext(DatasetsContext);
  const [chartEditModalOpen, setChartEditModalOpen] = useState<boolean>(false);
  const [minTempValue, setMinTempValue] = React.useState<number | null>(null);
  const [maxTempValue, setMaxTempValue] = React.useState<number | null>(null);
  const [numBinsTemp, setNumBinsTemp] = React.useState<number | null>(null);
  const { triggerToast } = useContext(ToastContext);

  const handleApply = async () => {

    if (minTempValue === null || maxTempValue === null || numBinsTemp === null) {
      triggerToast(ToastType.Warning, "Undefined Input", "Please specify all inputs");
      return;
    }

    if (maxTempValue <= minTempValue) {
      triggerToast(ToastType.Warning, "Invalid Bounds", "Max must be greater than min");
      return;
    }

    if (numBinsTemp <= 0) {
      triggerToast(ToastType.Warning, "Invalid Number of Bins", "Number of bins must be greater than 0");
      return;
    }

    // Update the primary limits
    setMinValue(minTempValue);
    setMaxValue(maxTempValue);
    setNumBins(numBinsTemp);
    setChangeFlag(minTempValue + maxTempValue + numBinsTemp);
    setChartEditModalOpen(false);
  };

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: KeyboardEvent) => {
      if (!chartEditModalOpen || keyCode !== 27) return;
      setChartEditModalOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    const limits = calculateLimits(context.data as ParametricQuantitative[])
    if (!limits) return;
    setMinTempValue(limits.minValue);
    setMaxTempValue(limits.maxValue);
    setNumBinsTemp(limits.numBins);
  }, [context.data])

  return (
    <Modal.Root open={chartEditModalOpen} onOpenChange={(value) => setChartEditModalOpen(value)}>
      <Modal.Trigger asChild>
        <Button size="xs" variant="outline" symmetric><FontAwesomeIcon icon={faGear} /></Button>
      </Modal.Trigger>
      <Modal.Overlay />
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Chart Configuration</Modal.Title>
          <Modal.HeaderClose />
        </Modal.Header>
        <Modal.Main className="gap-y-4">

          <div>
            <label className="mb-1 block text-sm font-medium">Minimum</label>
            <NumberInput
              type="number"
              value={minTempValue ?? ""}
              onValueChange={setMinTempValue}
              placeholder="Minimum x-axis value"
            />
          </div>
          <div>
            <label className="mb-1 block text-sm font-medium">Maximum</label>
            <NumberInput
              type="number"
              value={maxTempValue ?? ""}
              onValueChange={setMaxTempValue}
              placeholder="Maximum x-axis value"
            />
          </div>
          <div>
            <label className="mb-1 block text-sm font-medium">Number of Bins</label>
            <NumberInput
              type="number"
              value={numBinsTemp ?? ""}
              onValueChange={setNumBinsTemp}
              placeholder="Number of Bins"
            />
          </div>
        </Modal.Main>
        <Modal.Footer className="sm:justify-start">
          <div className="flex gap-2">
            <Modal.Close asChild>
              <Button size="sm" variant="outline">
                Close
              </Button>
            </Modal.Close>
            <Button size="sm" onClick={handleApply}>
              Apply
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};

export default DatasetChartEditModal;

